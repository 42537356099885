(function () {
  'use strict';

  class Notifications {
    constructor(NotificationsRest) {
      this.NotificationsRest = NotificationsRest;
    }

    notificationsIndex(notification = {}, callback = angular.noop) {
      return this.NotificationsRest.notifications().get(notification,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Notifications
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Notifications', Notifications);
}());
